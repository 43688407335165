zbzx.vue<template>
  <div class="tzhh">
    <div class="header">
      <div class="h1">投资黄骅</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item to="/tzhh" >投资黄骅</el-breadcrumb-item>
        <el-breadcrumb-item>品牌故事</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="margin: 100px 0">
        <el-carousel :interval="4000" type="card" :autoplay="false" indicator-position="none" height="500px">
          <el-carousel-item v-for="item in landmarkBrandList" :key="item">
              <div style="width: 500px;height: 300px">
                <el-image :src="item.image"></el-image>
              </div>
              <div class="desc " style="padding: 20px">
                <div class="name">   {{item.name}}</div>
                <div>   {{item.content}}</div>
              </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>

import {landmarkbrandindex} from "../../request/moudle/tzhh";

export default {
  name: "index",
  data(){
    return {
      landmarkBrandList:[],
    }
  },
  mounted() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.tzhh.landmarkbrandindex().then((res)=>{
        this.landmarkBrandList=res.data.landmarkBrandList
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  background: #fff;
  width: 1200px;
  margin: 20px auto;
}
/deep/ .el-carousel__item{
  opacity: 0.5;
}
/deep/ .is-active{
  opacity: 1;
}
.tzhh {
  background: #fff;
  .header {
    height: 220px;
    background: url("../../assets/banner/tzhh.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    color: #fff;
    .h1 {
      font-size: 42px;
      font-weight: 500;
      margin-top: 50px;
      margin-left: 350px;
    }
  }
  .desc{
    .name{
      font-weight: bolder;
      margin-bottom: 8px;
    }
    width: 460px;
    height: 200px;
    background: #fff;
  }
}
</style>